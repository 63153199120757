const base = {
  color: {
    lightBlue: '#B3E5FC',
    blue: '#03A9F4',
  },
  font: { mono: `'Fira Code', monospace` },
  breakpoint: '640px',
};

const darkColors = {
  background: '#313131',
  text: '#f5f5f5',
};

const lightColors = {
  background: '#f5f5f5',
  text: '#313131',
};

export default isDark => ({
  modeColors: isDark ? darkColors : lightColors,
  ...base,
});

import React from 'react';
import styled from 'styled-components';

import Paragraph from './shared/Paragraph';
import Anchor from './shared/Anchor';

const Footer = styled.footer`
  padding-bottom: 2rem;
`;

export default () => (
  <Footer>
    <Paragraph>
      View my complete resume on{' '}
      <Anchor href="https://www.linkedin.com/in/filip-janevski">
        LinkedIn
      </Anchor>
      .<br />
      You can also download it as PDF on{' '}
      <Anchor href="https://drive.google.com/open?id=1OaEAtYbPP0KoQVI6p3czTkzKy-Uui5CN">
        Google Drive
      </Anchor>
      .<br />
      <br />
      Follow me on <Anchor href="https://twitter.com/0x96f/">Twitter</Anchor>
      .
      <br />
      See some of my code on{' '}
      <Anchor href="https://github.com/filipjanevski">GitHub</Anchor>.
    </Paragraph>
  </Footer>
);
